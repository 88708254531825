import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrNewScanForm'
})
export default class GtrNewScanForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    data () {
      return {
        loading: false,
        line: 3,
        barcode_num: '',
        value: false,
        submitting: false,
        date_time: ''
      }
    }

    @Watch('visible', { immediate: true })
    onVisibiltyChange (payload: any) {
      this.$data.value = payload
    }

    onClose () {
      this.$data.user_group = {
        name: null,
        description: null
      }
      const observer = (this.$refs.observerForm as any)
      observer.reset()
      this.$data.value = false
      this.$emit('close')
    }

    async submit () {
      const form = (this.$refs.newScanForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const payload: any = {
            barcode_num: this.$data.barcode_num,
            line: this.$data.line,
            date_time: this.$data.date_time,
            file_name: this.$route.params.file_name,
            deviceid: this.$route.params.deviceid,
            event_uuid: this.$route.params.event_uuid
          }
          await this.$store.dispatch('scans/addScan', payload)
          Container.get(Notification).success('Scan successfully created.')
          this.$data.user_group = {
            name: null,
            description: null
          }
          this.onClose()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }
}
