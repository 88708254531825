import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import GtrNewScanForm from './new/new_scan.form.vue'

@Component({
  name: 'GtrAdminSetEventView',
  computed: {
    ...mapState('scans', ['scan_data', 'scan', 'original_scan_data']),
    ...mapState('event', ['events'])
  },
  components: {
    'gtr-new-scan-form': GtrNewScanForm
  }
})
export default class GtrAdminSetEventView extends Vue {
  data () {
    return {
      showRawUploads: false,
      dataForBarcodes: {},
      file_name: this.$route.params.file_name,
      deviceid: this.$route.params.deviceid,
      total_scans: 0,
      eventSelectedUuid: '',
      events2: [],
      search: '',
      loading: false,
      table: {
        headers: [
          { text: 'Barcode', align: 'start', sortable: true, value: 'barcode_num' },
          { text: 'Date Scanned', value: 'date_time' },
          { text: 'Time Inserted', value: 'created_at' },
          { text: 'Hostname', value: 'hostname' },
          { text: 'Line #', value: 'line' },
          { text: 'Status', value: 'status' },
          { text: 'Data', value: 'load_data' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      tableOriginalScans: {
        headers: [
          { text: 'Uploaded At', align: 'start', sortable: true, value: 'created_at' },
          { text: 'Data', value: 'value' }
        ],
        items: []
      },
      forms: {
        showNewScanForm: false
      },
      companyToDelete: null
    }
  }

  @Watch('scan_data')
  onScanDataChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
      for (let i = 0; i < payload.length; i++) {
        Vue.set(this.$data.table.items[i], 'resolved_data', null)
      }
      this.$data.total_scans = payload.length

      this.$store.dispatch('scans/fetchScanByUUID', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid: this.$route.params.event_uuid, row_uuid: payload[0].uuid })
      this.$store.dispatch('scans/fetchScanByUUID', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid: this.$route.params.event_uuid, row_uuid: payload[1].uuid })
    }
  }

  @Watch('original_scan_data')
  onOriginalScanDataChange (payload: any) {
    if (payload) {
      for (let i = 0; i < payload.length; i++) {
        payload[i].value = payload[i].value.replaceAll('\n', '<br>')
        payload[i].value = payload[i].value.replaceAll(',', ', ')
      }
      this.$data.tableOriginalScans.items = payload
    }
  }

  @Watch('scan')
  onScanChange (payload: any) {
    for (const index in this.$data.table.items) {
      const item = this.$data.table.items[index]
      if (item.uuid === payload.uuid) {
        item.resolved_data = payload.resolved_data
        this.$data.table.items[index] = item
      }
    }
  }

  showNewScanForm () {
    this.$data.forms.showNewScanForm = true
  }

  handleCloseNewScanForm () {
    this.$data.forms.showNewScanForm = false
  }

  loadDataForScan (payload) {
    this.$store.dispatch('scans/fetchScanByUUID', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid: this.$route.params.event_uuid, row_uuid: payload.uuid })
  }

  async processScans () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('scans/processScansByFileNameAndDeviceIdForEvent', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid: this.$route.params.event_uuid })
      Container.get(Notification).success('Finished processing.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
      return error
    } finally {
      this.$data.loading = false
    }
  }

  async mounted () {
    this.$store.dispatch('scans/fetchScansByFileNameAndDeviceIdForEvent', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid: this.$route.params.event_uuid })
    this.$store.dispatch('scans/fetchOriginalScansForEvent', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid: this.$route.params.event_uuid })
  }

  itemRowBackground (item) {
    return item.line <= 2 ? '' : (item.status === 0 ? 'style-bad' : 'style-good')
  }

  deleteScan (item) {
    if (confirm('Are you sure you want to delete this scan')) {
      this.$store.dispatch('scans/deleteScanByUUID', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid: this.$route.params.event_uuid, row_uuid: item.uuid })
      Container.get(Notification).success('Scan deleted.')
    }
  }
}
